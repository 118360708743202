import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import video from './assets/videos/m.mp4';
import logo from './assets/images/snipps.png';
import icon from './assets/images/icon.png';
import background from './assets/images/bg.jpg';
import { IoVolumeMute as VolumeMute, IoVolumeHigh as VolumeHigh } from 'react-icons/io5'
import {BrowserView, MobileView} from 'react-device-detect';

const SNIPPS = [
  {
    key: 'A',
    image: 'https://storage.googleapis.com/voystory-msgs-dev-p/tmp/snipps/ko.png',
    sound: 'https://storage.googleapis.com/voystory-msgs-dev-p/tmp/snipps/ko.mp3',
  },
  {
    key: 'B',
    image: 'https://storage.googleapis.com/voystory-msgs-dev-p/tmp/snipps/pew.png',
    sound: 'https://storage.googleapis.com/voystory-msgs-dev-p/tmp/snipps/pew.mp3',
  },
  {
    key: 'C',
    image: 'https://storage.googleapis.com/voystory-msgs-dev-p/tmp/snipps/wow.png',
    sound: 'https://storage.googleapis.com/voystory-msgs-dev-p/tmp/snipps/wow.mp3',
  },
  {
    key: 'D',
    image: 'https://storage.googleapis.com/voystory-msgs-dev-p/tmp/snipps/ahh.png',
    sound: 'https://storage.googleapis.com/voystory-msgs-dev-p/tmp/snipps/ahh.mp3',
  },
  {
    key: 'E',
    image: 'https://storage.googleapis.com/voystory-msgs-dev-p/tmp/snipps/john.png',
    sound: 'https://storage.googleapis.com/voystory-msgs-dev-p/tmp/snipps/john.mp3',
  },
];

export function WebApp() {
  const audio = useRef();

  const getEarlyAccess = useCallback(() => {
    window.location.href = "https://sbty46c9mvg.typeform.com/to/vEB18eKE";
  }, []);
  
  const playSnipps = useCallback(() => {
    audio.current.currentTime = 0;
    audio.current.play();
  }, []);
  
  return (
    <>
      <BrowserView className='webapp' style={{ backgroundImage: `url(${background})` }}>
        <div className='webapp-left'>
          <div className='webapp-header'>
            <img src={logo} alt='snipps' className='webapp-logo' onClick={playSnipps}/>
            <audio ref={audio} src={'https://storage.googleapis.com/voystory-msgs-dev-p/tmp/snipps/snipps.mp3'}/>
          </div>
          <div className='webapp-content'>
            <h1 className='webapp-title'>Create viral videos</h1>
            <h3 className='webapp-subtitle'>By combining trending sounds and visuals at key moments</h3>
            <div className='webapp-snipps'>
              { SNIPPS.map(({ key, sound, image }) => <Snipp key={key} sound={sound} image={image} />) }
            </div>
          </div>
          <div className='webapp-footer'>
            <img src={icon} alt='snipps' className='webapp-icon'/>
            <button className='webapp-join-button' onClick={getEarlyAccess}>
              <div className='webapp-join-button-text'>Get Early Access</div>
            </button>
          </div>
        </div>
        <div className='webapp-right'>
          <Video />
        </div>
      </BrowserView>
      <MobileView className='webapp' style={{ backgroundImage: `url(${background})` }}>
        <div className='webapp-container'>
          <div className='webapp-header'>
            <img src={logo} alt='snipps' className='webapp-logo' onClick={playSnipps}/>
            <audio ref={audio} src={'https://storage.googleapis.com/voystory-msgs-dev-p/tmp/snipps/snipps.mp3'}/>
          </div>
          <div className='webapp-content'>
            <h1 className='webapp-title'>Create viral videos</h1>
            <h3 className='webapp-subtitle'>By combining trending sounds and visuals at key moments</h3>
            <Video />
            <div className='webapp-snipps'>
              { SNIPPS.slice(0, 4).map(({ key, sound, image }) => <Snipp key={key} sound={sound} image={image} />) }
            </div>
            <button className='webapp-join-button' onClick={getEarlyAccess}>
              <div className='webapp-join-button-text'>Get Early Access</div>
            </button>
          </div>
        </div>
      </MobileView>
    </>
  );
}

function Video() {
  const [muted, setMuted] = useState(true);

  const toggle = useCallback(() => setMuted((v) => !v), []);

  return (
    <div className='webapp-video' onClick={toggle}>
      <video className='webapp-video' height='100%' src={video} muted={muted} autoplay autoPlay={true} loop={true} />
      { muted ? <VolumeMute className='video-volume' /> : <VolumeHigh className='video-volume' /> }
    </div>
  );
}

function Snipp({ image, sound }) {
  const ref = useRef(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    let cancel = false;
    ref.current.addEventListener("ended", () => {
      if (cancel) return;
      setPlaying(false);
    });

    return () => cancel = true;
  }, []);

  const handleClick = useCallback(() => {
    setPlaying(true);
    ref.current.currentTime = 0;
    ref.current.play();
  }, []);

  return (
    <div className={ playing? 'webapp-snipp playing' : 'webapp-snipp' } onClick={handleClick}>
      <img src={image} alt='snipp' className={ playing? 'webapp-snipp playing' : 'webapp-snipp' }/>
      <audio ref={ref} id="audio" src={sound}/>
    </div>
  );
}