import './App.css';
import { WebApp } from './WebApp';

function App() {
  return (
    <WebApp />
  );
}

export default App;
